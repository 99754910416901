<template>
    <div>
        <div class="row" v-for="(item, index) in items" :key="index">
            <div class="col-lg-3">
                <label>
                    カテゴリ {{ index + 1 }}
                    <span class="text-danger" v-if="index == 0">*</span>
                    <button type="button" class="btn btn-sm btn-danger" v-if="index > 0"
                        @click="() => removeCategory(index)">X</button>
                </label>
            </div>
            <div class="col-lg-9">
                <div>
                    <SelectInline
                         :label="$t('lesson.form.category')"
                         :labelErr="$t('lesson.form.category') + (index + 1)"
                         :options="categories.data"
                        v-model="item.category_id" :required="index == 0" 
                        @change="(id) => handleCategory(id, index)" />
                </div>
                <div class="mt-1">
                    <SelectInline 
                        :label="$t('lesson.form.child_category')"
                        :labelErr="$t('lesson.form.child_category') + (index + 1)"
                        :name="`child_category_${index+1}`" 
                        :options="childCategories[index]" v-model="item.sub_category_id" 
                        :required="_required(index)"
                        @change="(id) => handleChildCategory(id, index)" />
                </div>
                <hr />
            </div>
        </div>
        <div class="row" v-if="items.length < 3">
            <div class="col-lg-12 text-center mt-2">
                <button type="button" class="btn btn-success" @click="addCategory">＋カテゴリを追加（最大3件）</button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "FormCategory",
    data() {
        return {
            childCategories: [],
        }
    },
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    created() {
        this.fetchCategoryMenus();
    },
    computed: {
        ...mapGetters({
            categories: "lessonCategory/getCategoryMenu"
        }),
    },
    methods: {
        ...mapActions({
            fetchCategoryMenus: "lessonCategory/fetchCategoryMenus",
        }),
        handleCategory(id, index = 0) {
            this.items[index].category_id = id;
            let category = this.categories.data.find(o => o.id == id);
            this.items[index].sub_category_id = null;
            this.childCategories[index] = category?.children ?? [];
        },
        handleChildCategory(id, index = 0) {
            this.items[index].sub_category_id = id;
        },
        addCategory() {
            if (this.items.length < 3) {

                this.items.push({
                    category_id: null,
                    sub_category_id: null
                });
            }
        },
        removeCategory(index) {
            this.items.splice(index, 1);
        },
        _required(index) {
            return index == 0 || this.items[index].category_id != "";
        }
    },
    watch: {
        items: function (newValue, oldValue) {
            if (newValue != oldValue && newValue.length && this.categories.data.length) {
                newValue.map((item, index) => {
                    let category = this.categories.data.find(o => o.id == item.category_id);
                    this.childCategories[index] = category.children;
                });
            }
        },
        categories: function (newValue, oldValue) {
            if (newValue != oldValue && newValue.data.length && this.items.length) {
                this.items.map((item, index) => {
                    let category = newValue.data.find(o => o.id == item.category_id);
                    this.childCategories[index] = category.children;
                });
            }
        },
    },
}
</script>